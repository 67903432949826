<template>
  <a-spin :spinning="page_loading">
    <sdPageHeader title="Hesap Listesi (Cargong)" />
    <Main>
      <sdCards headless>
        <a-table
          :data-source="accounts"
          :columns="columns"
          :pagination="pagination"
          row-key="id"
          @change="handleTableChange"
        >
          <template
            #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
          >
            <div style="padding: 8px">
              <a-input
                ref="searchInput"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
              />
              <a-button
                type="primary"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
              >
                <template #icon>
                  <SearchOutlined />
                </template>
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px"
                @click="handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
          </template>
          <template #filterIcon="filtered">
            <search-outlined
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
          </template>

          <template #lastAuthDate="{ record }">
            <span>{{ record.last_login_diff }} </span>
          </template>
          <template #lastAuthCount="{ record }">
            <span v-if="record.login_count > 0">
              <a-badge
                :count="record.login_count"
                :number-style="{
                  backgroundColor: '#5f63f215',
                  color: '#5f63f2'
                }"
              /></span>
            <span v-else />
          </template>

          <template #cargongCreditShow="{ record }">
            <span>{{ record.credit }}&nbsp;</span>
            <sdFeatherIcons
              type="arrow-up-circle"
              size="14"
            />
          </template>

          <template #cargongSpentShow="{ record }">
            <span>{{ record.spent }} &nbsp;</span>
            <sdFeatherIcons
              type="arrow-down-circle"
              size="14"
            />
          </template>

          <template #cargongRemainShow="{ record }">
            <span
              style="color: forestgreen; font-weight: bold"
            >{{ record.remain }}&nbsp;</span>
          </template>

          <template #cargongCreditDetail="{ record }">
            <sdButton
              size="default"
              transparented
              type="primary"
              :loading="page_loading"
              @click="creditDetailClick(record.id)"
            >
              <sdFeatherIcons
                type="layers"
                size="14"
              />
              <span>Detay</span>
            </sdButton>
          </template>

          <template #cargongLoginUrl="{ record }">
            <sdButton
              size="default"
              transparented
              type="primary"
              @click="autoLogin(record.id)"
            >
              <sdFeatherIcons
                type="arrow-right-circle"
                size="14"
              />
            </sdButton>
          </template>
        </a-table>
      </sdCards>

      <a-drawer
        title="Kontör Hareketleri"
        placement="right"
        width="300"
        :closable="true"
        :visible="showCreditActivites"
        @close="showCreditActivites = false"
      >
        <a-row>
          <a-col :span="24">
            <ul>
              <li
                v-for="item in creditActivities"
                :key="item.id"
              >
                <b>{{ item.credit }} yüklendi</b>
                <br>
                {{ item.created_at }}<br>
                {{ item.description ?? 'Açıklama Yok' }}
                <br>
                <br>
              </li>
            </ul>
          </a-col>
        </a-row>
      </a-drawer>
    </Main>
  </a-spin>
</template>
<script>
import { Main } from './../styled'
import { SearchOutlined } from '@ant-design/icons-vue'
import { defineComponent } from 'vue'
import { prepareTableData } from '@/utility/utility'

export default defineComponent({
  components: {
    Main,
    SearchOutlined,
  },
  data() {
    return {
      accounts: [],
      searchText: '',
      searchedColumn: '',
      creditActivities: [],
      showCreditActivites: false,
      page_loading: true,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
        showSizeChanger: true,
        pageSizeOptions: ['10', '30', '50', '100', '200'],
      },
      columns: [
        {
          title: 'İsim',
          dataIndex: 'name',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.$refs.searchInput.focus()
              }, 100)
            }
          },
        },
        {
          title: 'Yüklenen',
          dataIndex: 'credit',
          align: 'right',
          slots: {
            customRender: 'cargongCreditShow',
          },
          sorter: true,
        },
        {
          title: 'Harcanan',
          dataIndex: 'spent',
          align: 'right',
          slots: {
            customRender: 'cargongSpentShow',
          },
          sorter: true,
        },
        {
          title: 'Kalan',
          dataIndex: 'remain',
          align: 'right',
          slots: {
            customRender: 'cargongRemainShow',
          },
          sorter: true,
        },
        {
          title: 'Kontör Detayı',
          slots: {
            customRender: 'cargongCreditDetail',
          },
        },
        {
          title: 'Login',
          dataIndex: 'login_url',
          slots: {
            customRender: 'cargongLoginUrl',
          },
        },
        {
          title: 'Son Oturum',
          dataIndex: 'last_login_diff',
          key: 'last_login_diff',
          slots: {
            customRender: 'lastAuthDate',
          },
        },
        {
          title: 'Oturum Sayısı',
          dataIndex: 'last_login_at',
          key: 'last_login_at',
          slots: {
            customRender: 'lastAuthCount',
          },
        },
      ],
    }
  },
  mounted() {
    this.loadPageData()
  },
  methods: {
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    autoLogin(accountId) {
      this.page_loading = true
      this.$restMethods.postForNoc('/login-as-account/' + accountId).then(response => {
        if ( ! response.hasError()) {
          window.open(response.getData().redirect_url, '_blank')
        }
        this.page_loading = false
      })
    },
    creditDetailClick(accountId) {
      this.page_loading = true
      this.creditActivities = []
      this.$restMethods.postForNoc('/get-account-credit-activites/' + accountId).then(response => {
        if ( ! response.hasError()) {
          this.creditActivities = response.getData().items
          this.showCreditActivites = true
        }
        this.page_loading = false
      })
    },
    convertDateFormat(dateStr) {
      const parts = dateStr.split(' ')
      const dateParts = parts[0].split('/')
      return new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]} ${parts[1]}`,
      )
    },
    getTimeDifference(pastDate) {
      if ( ! pastDate) return '-'

      const today = new Date()
      const past = this.convertDateFormat(pastDate)
      const diffInTime = today - past
      const diffInMinutes = Math.floor(diffInTime / (1000 * 60))
      const diffInHours = Math.floor(diffInTime / (1000 * 3600))
      const diffInDays = Math.floor(diffInHours / 24)

      if (diffInDays === 0) {
        if (diffInHours === 0) {
          return diffInMinutes === 1
            ? '1 dakika önce'
            : `${diffInMinutes} dakika önce`
        }
        return diffInHours === 1 ? '1 saat önce' : `${diffInHours} saat önce`
      } else if (diffInDays < 7) {
        return diffInDays === 1 ? '1 gün önce' : `${diffInDays} gün önce`
      } else if (diffInDays < 30) {
        const weeks = Math.floor(diffInDays / 7)
        return weeks === 1 ? '1 hafta önce' : `${weeks} hafta önce`
      } else {
        const months = Math.floor(diffInDays / 30)
        return months === 1 ? '1 ay önce' : `${months} ay önce`
      }
    },
    loadPageData(tableData = undefined) {

      tableData = typeof tableData === 'undefined' ? prepareTableData(this.pagination) : tableData

      this.page_loading = true
      this.$restMethods
        .postForNoc('/account-list-for-cargong', {
          ...tableData,
        })
        .then(response => {
          if ( ! response.hasError()) {

            const data = response.getData()

            this.accounts = data.accounts.map(customer => {
              customer.last_login_diff = this.getTimeDifference(
                customer.last_login_at,
              )
              return customer
            })
            this.pagination = data.pagination

          }
          this.page_loading = false
        })
    },
    handleTableChange(page, filters, sorter) {
      this.loadPageData(prepareTableData(page, filters, sorter))
    },
  },
})
</script>